// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgMessageText = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.75 5c0-.69.56-1.25 1.25-1.25h14c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H7.961a2.75 2.75 0 0 0-2.147 1.032l-2.064 2.58zM5 2.25A2.75 2.75 0 0 0 2.25 5v15.29c0 1.276 1.607 1.84 2.404.843l2.331-2.914a1.25 1.25 0 0 1 .976-.469H19A2.75 2.75 0 0 0 21.75 15V5A2.75 2.75 0 0 0 19 2.25zM6.25 8A.75.75 0 0 1 7 7.25h6a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 8m0 4a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75"
    />
  </svg>
);
export const MessageTextIcon = forwardRef(SvgMessageText);
