// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgReply = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.53 2.47a.75.75 0 0 1 0 1.06L3.81 9.25H16c2.182 0 3.883.755 5.038 1.947 1.147 1.182 1.712 2.75 1.712 4.303s-.565 3.12-1.712 4.303C19.883 20.995 18.182 21.75 16 21.75a.75.75 0 0 1 0-1.5c1.818 0 3.117-.62 3.962-1.49.853-.88 1.288-2.063 1.288-3.26s-.435-2.38-1.288-3.26c-.845-.87-2.144-1.49-3.962-1.49H3.81l5.72 5.72a.75.75 0 1 1-1.06 1.06l-7-7a.75.75 0 0 1 0-1.06l7-7a.75.75 0 0 1 1.06 0"
    />
  </svg>
);
export const ReplyIcon = forwardRef(SvgReply);
